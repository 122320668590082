import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import Search from './components/Search';
import VueRouter from 'vue-router';

Vue.config.productionTip = false

const routes = [
  { path: '/year/:year?/from/:from?/to/:to?',
    name: 'app',
    component: Search },
  { path: '/', redirect: { name: 'app' }}
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.use(VueRouter)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
