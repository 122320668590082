<template>
    <v-container style="max-width: 600px;">
        <v-card>
        <v-timeline dense clipped class="pt-0">

            <template v-for="(leg, i) in journey.legs"
                      >
                <v-timeline-item
                        class="mb-0 pb-0"
                        v-bind:color="i===0 ? 'green' : 'grey'"
                        icon-color="grey lighten-2"
                        small
                        v-bind:key="leg.from.name + leg.to.name"
                >
                    <v-row  align="center">
                        <v-col cols="5">
                            <div v-if="i>0">
                                an {{ journey.legs[i-1].arrival | formatTime }}
                            </div>
                            <div>
                                ab {{ leg.departure | formatTime }}
                            </div>
                        </v-col>
                        <v-col cols="6">{{ leg.from.name }}</v-col>
                    </v-row>
                </v-timeline-item>
                <v-timeline-item
                        class="mb-0 pb-0"
                        hide-dot
                        v-bind:key="leg.from.name + leg.to.name + '-line'"
                >
                    <v-row>
                        <v-col cols="7" offset="5" class="py-0">
                          <v-expansion-panels v-if="leg.via.length > 0" flat>
                            <v-expansion-panel >
                              <v-expansion-panel-header>
                                <v-row>
                                <v-btn
                                  :href="'https://www.fernbahn.de/datenbank/suche/?zug_id=' + leg.train_id"
                                  target="_blank"
                                  text
                              >{{ leg.train }}</v-btn></v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div v-for="via in leg.via" v-bind:key="via.name">
                                  via {{ via.name }}
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-btn v-if="leg.via.length == 0"
                              :href="'https://www.fernbahn.de/datenbank/suche/?zug_id=' + leg.train_id"
                              target="_blank"
                              text
                          >{{ leg.train }}</v-btn>
                        </v-col>
                    </v-row>


                </v-timeline-item>
            </template>
            <v-timeline-item
                    class="mb-0 pb-0"
                    color="red"
                    icon-color="grey lighten-2"
                    small
            >
                <v-row  align="center">
                    <v-col cols="5">
                        <div>
                            an {{ journey.legs[journey.legs.length-1].arrival | formatTime }}
                        </div>
                    </v-col>
                    <v-col cols="6">{{ journey.legs[journey.legs.length-1].to.name }}</v-col>
                </v-row>
            </v-timeline-item>


        </v-timeline>
        </v-card>
    </v-container>
</template>

<script>

    export default {
        name: 'JourneyDetails',
        data: () => ({
            //journey: [] //[{origin:{name:"Ahrensfelde"},line:{name:"ICE 948"}, arrivalDelay: 60, departureDelay: 180,
              //  departurePlatform:12, destination:{name:"Hannover Hbf"}, direction: "Köln", arrival: "2019-10-30T03:19:00+01:00", departure:"2019-10-30T03:09:00+01:00"},
               // {origin:{name:"blubb"},line:{name:"IC 948"},
               //     arrivalPlatform:2, destination:{name:"Ulm Hbf"}, direction: "München", arrival: "2019-10-30T03:09:00+01:00", departure:"2019-10-30T03:09:00+01:00"}]
        }),
        props: ['journey'],
        mounted: () => {},
        methods: {

        },
    };
</script>

<style scoped>
</style>
