<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-container style="max-width: 600px;">
        <v-card
            color="blue lighten-2"
            dark
        >
          <v-card-text>
            <v-row>
              <v-col
                  cols="6"
              >
            <v-select
                v-model="year"
                :items="years"
                label="Jahr"
                single-line
                prepend-icon="mdi-calendar-today"
            ></v-select>
              </v-col>
              <v-col cols="6">
            <v-text-field
                v-model="min_transfer"
                suffix="min. tr."
                hide-details
                single-line
                label="min. tr."
                type="number"
            />
              </v-col>
            </v-row>
            <v-autocomplete
                v-model="depselect"
                :items="depitems"
                :loading="deploading"
                :search-input.sync="depsearch"
                color="white"
                cache-items
                hide-no-data
                hide-details
                label="Start"
                prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <v-autocomplete
                v-model="arrselect"
                :items="arritems"
                :loading="arrloading"
                :search-input.sync="arrsearch"
                color="white"
                cache-items
                hide-no-data
                hide-details
                label="Destination"
                prepend-icon="mdi-database-search"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!depselect || !arrselect"
                color="green darken-3"
                @click="searchTimes"
            >
              Times
              <v-icon right>mdi-clock-time-nine</v-icon>
            </v-btn>
            <v-btn
                :disabled="!depselect || !arrselect || !year"
                color="grey darken-3"
                @click="searchjourney"
            >
              Search
              <v-icon right>mdi-train</v-icon> <v-progress-circular v-if="seachloading"
                indeterminate
                color="primary"
            ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
      <v-container style="max-width: 600px;">
        <v-alert v-if="noroute"
                 type="warning"
        >No route found</v-alert>
      </v-container>
      <v-container style="max-width: 600px;">
        <v-card v-if="chartloaded || chartloading"
                color="white lighten-2"
                dark
        >
          <v-card-text>
            <div class="text-center" v-if="chartloading">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
            <TimeChart v-if="chartloaded"
                       :chart-data="chartdata"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
    <v-col cols="12" md="4" v-for="(journeys, i) in journey_cols" v-bind:key="i">
      <JourneyDetails fluid
                      v-for="(journey, index) in journeys"
                      v-bind:journey="journey"
                      v-bind:key="index"
      ></JourneyDetails>
    </v-col>
  </v-row>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import JourneyDetails from "@/components/JourneyDetails";
import TimeChart from "@/components/TimeChart";
import axios from 'axios';


export default {
  name: 'Search',

  components: {
    JourneyDetails,
    TimeChart
  },

  data() { return {
    journey_cols: [],
    seachloading: false,
    chartloaded: false,
    chartloading: false,
    chartdata: Object(),

    min_transfer: 4,

    deploading: false,
    depitems: [],
    depsearch: null,
    depselect: null,

    arrloading: false,
    arritems: [],
    arrsearch: null,
    arrselect: null,

    years: [1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2030],
    year: null,
    noroute: false,
    }
  },
  async mounted () {
    this.depitems = [ this.$route.params['from'] ];
    this.depselect = this.$route.params['from'];
    this.arritems = [ this.$route.params['to'] ];
    this.arrselect = this.$route.params['to'];

    this.year = parseInt(this.$route.params['year']);

    if (this.depselect && this.arrselect ) {
      this.searchTimes();
      if (this.year){
        this.searchjourney();
      }
    }

  },
  watch: {
    '$route.params.to'(val) {
      this.arrselect = val;
    },
    '$route.params.from'(val) {
      this.depselect = val;
    },
    '$route.params.year'(val) {
      this.year = parseInt(val);
    },
    arrselect(val) {
      this.$router.push( { params: { ...this.$route.params, to: val } }).catch(()=>{});
    },
    depselect(val) {
      this.$router.push( { params: { ...this.$route.params, 'from': val } }).catch(()=>{});
    },
    year(val) {
      this.$router.push( { params: { ...this.$route.params, 'year': val } }).catch(()=>{});
    },
    arrsearch (val) {
      val && val !== this.arrselect && this.arrQuerySelections(val)
    },
    depsearch (val) {
      val && val !== this.depselect && this.depQuerySelections(val)
    },
  },
  methods: {
    depQuerySelections (v) {
      this.deploading = true
      // Simulated ajax query
      axios.get(`/datenbank/suche/js/ajax.bahnhof.php?term=${v}`)
          .then((res) => {
            this.depitems = res.data.map(el => el.value);
            this.deploading = false
          }).catch(err => {
        console.log(err)
      }).finally(() => (this.deploading = false));
    },
    arrQuerySelections (v) {
      this.arrloading = true
      // Simulated ajax query
      axios.get(`/datenbank/suche/js/ajax.bahnhof.php?term=${v}`)
          .then((res) => {
            this.arritems = res.data.map(el => el.value);
            this.arrloading = false
          }).catch(err => {
        console.log(err)
      }).finally(() => (this.arrloading = false));
    },
    searchjourney() {
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.seachloading = true;
        console.log(this.depselect + " to " + this.arrselect);

        axios.get(`/api/route/${this.year}/from/${encodeURIComponent(this.depselect)}/to/${encodeURIComponent(this.arrselect)}?min_transfer=${this.min_transfer*60}`)
            .then((res) => {
              console.log(res.data);
              if( res.data.length == 0 ){
                this.noroute = true;
                this.journey_cols=[[],[]]
              }
              else {
                this.noroute = false;
                const half = Math.ceil(res.data.length / 2);
                this.journey_cols = [res.data.splice(0, half), res.data.splice(-half)];
              }
              this.seachloading = false;
            }, () => {console.error; this.seachloading=false;})
      }, 500)
    },
    searchTimes() {
      this.chartloading = true;
      this.chartloaded = false;
      axios.get(`/api/change/from/${encodeURIComponent(this.depselect)}/to/${encodeURIComponent(this.arrselect)}`)
          .then((res) => {
            let pairs = []
            //console.log(res.data);
            let cutoff = 0;
            for (let key in res.data) {
              //console.log(key, res.data[key]);
              let times = [];
              for (let time in res.data[key]) {
                times.push(res.data[key][time]/60)
              }
              times.sort();
              let dmed = times[Math.ceil(times.length/2)] * 1.5;
              if (dmed > cutoff) {
                cutoff = dmed
              }
            }
            for (let key in res.data) {
              //console.log(key, res.data[key]);

              for (let time in res.data[key]) {
                if (res.data[key][time]/60 < cutoff) {
                  let yr = key;
                  if (key === "2030") {
                    yr = 2025
                  }
                  pairs.push({x: yr, y: res.data[key][time] / 60})
                }
              }
            }
            console.log(pairs);
            //console.log(res.data.map((k,val) => {x: key, y: val}));
            this.chartdata = {datasets: [{label: `${this.depselect} to ${this.arrselect}`, data: pairs}]};
            this.chartloaded = true;
            this.chartloading = false;
          }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>
