<script>
import { Scatter, mixins } from "vue-chartjs";

const { reactiveProp } = mixins

export default {
  extends: Scatter,
  mixins: [ reactiveProp ],
  data () { return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Hours'
            },
            ticks: {
              stepSize: 30,
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                console.log(value, index, values)
                var ret = value;

                if (values.length < 7) {
                  if (value%60 === 0) {
                    ret = value / 60 + ":00"
                  }
                  else {
                    ret = parseInt(value / 60) + ":30";
                  }
                }
                else if (values.length < 15) {
                  if (value%60 === 0) {
                    ret =  value/60 + ":00";
                  }
                  else
                    ret = "";
                }
                else{
                  if (value%120 === 0) {
                    ret =  value/60 + ":00";
                  }
                  else
                    ret = "";
                }
                /*if (values.length > 10) {
                  if (value%120 === 60) {
                    ret = "a";
                  }
                }*/
                console.log(ret)
                return ret;
              }
            }
          }],
          xAxes: [{
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, _i, _v) {
                if ( _i === _v ) {
                  return
                }
                if (value === 2025) {
                  return  "//2030";
                }
                else { return value }
              }
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
};
</script>
