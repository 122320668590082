<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />-->

        <v-toolbar-title>Historic Routes</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.fernbahn.de/datenbank"
        target="_blank"
        text
      >
        <span class="mr-2">Datenbank</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
};
</script>
